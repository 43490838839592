import _micromatch from "micromatch";
import _normalizePath from "normalize-path";
import _path from "path";
var exports = {};
var micromatch = _micromatch;
var normalize = _normalizePath;
var path = _path; // required for tests.

var arrify = function (a) {
  return a == null ? [] : Array.isArray(a) ? a : [a];
};

var anymatch = function (criteria, value, returnIndex, startIndex, endIndex) {
  criteria = arrify(criteria);
  value = arrify(value);

  if (arguments.length === 1) {
    return anymatch.bind(null, criteria.map(function (criterion) {
      return typeof criterion === "string" && criterion[0] !== "!" ? micromatch.matcher(criterion) : criterion;
    }));
  }

  startIndex = startIndex || 0;
  var string = value[0];
  var altString, altValue;
  var matched = false;
  var matchIndex = -1;

  function testCriteria(criterion, index) {
    var result;

    switch (Object.prototype.toString.call(criterion)) {
      case "[object String]":
        result = string === criterion || altString && altString === criterion;
        result = result || micromatch.isMatch(string, criterion);
        break;

      case "[object RegExp]":
        result = criterion.test(string) || altString && criterion.test(altString);
        break;

      case "[object Function]":
        result = criterion.apply(null, value);
        result = result || altValue && criterion.apply(null, altValue);
        break;

      default:
        result = false;
    }

    if (result) {
      matchIndex = index + startIndex;
    }

    return result;
  }

  var crit = criteria;
  var negGlobs = crit.reduce(function (arr, criterion, index) {
    if (typeof criterion === "string" && criterion[0] === "!") {
      if (crit === criteria) {
        // make a copy before modifying
        crit = crit.slice();
      }

      crit[index] = null;
      arr.push(criterion.substr(1));
    }

    return arr;
  }, []);

  if (!negGlobs.length || !micromatch.any(string, negGlobs)) {
    if (path.sep === "\\" && typeof string === "string") {
      altString = normalize(string);
      altString = altString === string ? null : altString;
      if (altString) altValue = [altString].concat(value.slice(1));
    }

    matched = crit.slice(startIndex, endIndex).some(testCriteria);
  }

  return returnIndex === true ? matchIndex : matched;
};

exports = anymatch;
export default exports;